<template>
  <div>
    <!-- BOC:[header] -->
      <div class="d-flex pb-6 text-right">
          <v-spacer></v-spacer>
        <!-- <div class="pr-3">
          <SendCheckpoint
            :url="`${$api.servers.moderator}/v1/event/${parent.id}/checkpoint`"
          />
        </div> -->
        <v-btn
          color="primary"
          large
          :to="{
            name: 'PageModeratorEventEventInstitutionGroupEventEventInstitutionGroupMemberAdd',
            params: { parentId: parent.id, parent: parent, grandParentId: grandParent.id,
              grandParent: grandParent, },
          }"
        >
          + New Member
        </v-btn>
      </div>
    <!-- EOC -->
    <!-- BOC:[table] -->
    <BreadBrowseTable
      role="Moderator"
      :model="model"
      :url="`${$api.servers.event}/api/v1/${this.$_getLocale()}/moderator/event/${grandParent.id}/institutionGroup/${parent.id}/member`"
      :isSearchable="true"
      :parent="parent"
      :modelParent="modelParent"
      :grandParent="grandParent"
      :modelGrandParent="modelGrandParent"
    ></BreadBrowseTable>
    <!-- EOC -->
  </div>
</template>

<script>
// import SendCheckpoint from "@/components/Bread/Checkpoint/Message/SendCheckpoint";
//BOC:[model]
import modelGrandParent from "@/models/items/event";
import modelParent from "@/models/items/eventInstitutionGroup";
import model from "@/models/items/eventInstitutionGroupMember";
//EOC
//BOC:[table]
import BreadBrowseTable from "@/components/Bread/BreadBrowseTable";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //BOC:[table]
    BreadBrowseTable,
    //EOC
    //SendCheckpoint,
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  props: ["role", "parent","grandParent"],
  data: () => ({
    //BOC:[model]
    modelGrandParent: modelGrandParent,
    modelParent: modelParent,
    model: model,
    //EOC
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>